<template xmlns="http://www.w3.org/1999/html">
  <b-modal
    id="edit-membership-sidebar"
    ref="edit-membership-sidebar"
    :title="t('Memberships.editMembership')"
    :visible="isEditMembershipSidebarActive"
    backdrop
    no-header
    hide-footer
    @change="(val) => $emit('update:is-edit-membership-sidebar-active', val)"
  >
    <!--    <template #default="{ hide }">-->
    <template>
      <!-- BODY -->
      <validation-observer
        #default="{ invalid }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          id="formAddMembership"
          class="p-2"
          @submit.prevent="onSubmit(userData)"
          @reset.prevent="resetForm"
        >

          <!-- Integrante name-->
          <span v-if="membershipStatus == 1 && isEditMembershipSidebarActive">
            <validation-provider
              #default=""
              :name="$t('Clients.type.client')"
            >
              <b-form-group
                :label="$t('Clients.type.client')"
                label-for="memberDisplay"
              >
                <b-form-input
                  id="memberDisplay"
                  :value="selectedMembership.client.last_name + ', ' + selectedMembership.client.first_name"
                  :readonly="true"
                  trim
                />
                <input
                  id="clientId"
                  type="hidden"
                  :value="userData.id"
                >
              </b-form-group>
            </validation-provider>
          </span>
          <!-- Titular name-->
          <validation-provider
            #default=""
            :name="$t('Memberships.data.owner')"
          >
            <b-form-group
              :label="$t('Memberships.data.owner')"
              label-for="ownerDisplay"
            >
              <b-form-input
                id="ownerDisplay"
                :value="userData.last_name + ', ' + userData.first_name"
                :readonly="true"
                trim
              />
              <input
                id="clientId"
                type="hidden"
                :value="userData.id"
              >
            </b-form-group>
          </validation-provider>

          <!-- Type  -->
          <validation-provider
            #default="validationContext"
            :name="t('Memberships.typeTitle')"
            rules="required"
          >
            <b-form-group
              :label="t('Memberships.typeTitle')"
              label-for="typeId"
              :state="getValidationState(validationContext)"
              trim
              :placeholder="t('Memberships.data.planId')"
            >
              <v-select
                v-model="membershipData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="typeId"
                :disabled="true"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- New plan -->
          <validation-provider
            #default="validationContext"
            :name="t('Memberships.data.planId')"
            rules="required"
          >
            <b-form-group
              :label="t('Memberships.data.planId')"
              label-for="planId"
              :state="getValidationState(validationContext)"
              trim
              :placeholder="t('Memberships.data.planId')"
            >
              <v-select
                v-model="membershipData.planId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="activePlanOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="planId"
                :onchange="selectedPlan = activePlanOptions.find(option => option.value === membershipData.planId)"
                :disabled="true"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Credits plan-->
          <validation-provider
            #default="validationContext"
            :name="$t('Memberships.data.credits')"
            rules="required|integer|between:0,365"
          >
            <b-form-group
              :label="$t('Memberships.data.credits')"
              label-for="credits"
            >
              <b-form-input
                id="credits"
                :maxlength="3"
                v-model="membershipData.credits"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Start date -->
          <validation-provider
            #default="validationContext"
            :name="t('Memberships.data.startDate')"
            rules="required"
          >
            <b-form-group
              :label="t('Memberships.data.startDate')"
              label-for="startDate"
            >
              <flat-pickr
                id="startDate"
                v-model="membershipData.startDate"
                class="form-control text-muted"
                :config="{ dateFormat: 'd-m-Y'}"
                :disabled="true"
                placeholder="DD-MM-YYYY"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>

          <!-- End date -->
          <validation-provider
            #default="validationContext"
            :name="t('Memberships.data.endDate')"
          >
            <b-form-group
              :label="t('Memberships.data.endDate')"
              label-for="endDate"
            >
              <flat-pickr
                id="endDate"
                v-model="membershipData.endDate"
                class="form-control text-muted"
                :config="{ dateFormat: 'd-m-Y'}"
                placeholder="DD-MM-YYYY"
                :disabled="true"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Payment Debt status 4 adeuda-->
          <b-card v-if="membershipData.status == 4" class="card border-warning">
            <b-row>
              <b-col>
                <div class="mb-2">
                  {{ $t('Memberships.membershipList.totalDebt') }}
                </div>
                <div><h2> ${{ membershipData.debt }} </h2></div>
              </b-col>
              <b-col>
                <!-- paymentDebt -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('Memberships.membershipList.paymentDebt')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Memberships.membershipList.paymentDebt')"
                    label-for="discount"
                    label-class="mb-1"
                  >
                    <b-form-radio-group
                      id="discount"
                      v-model="membershipData.isPaymentDebt"
                      :options="paymentYesNoOptions"
                      :value="membershipData.isPaymentDebt"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- Payment Method -->
            <b-row v-if="membershipData.isPaymentDebt">
              <b-col>
              <validation-provider
                #default="validationContext"
                :name="t('Memberships.data.paymentMethodId')"
                rules="required"
            >
              <b-form-group
                  :label="t('Memberships.data.paymentMethodId')"
                  label-for="paymentMethodId"
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="t('Memberships.data.paymentMethodId')"
              >
                <v-select
                    v-model="membershipData.paymentMethodId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paymentMethodsOptionsActive"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="paymentMethodId"
                    :onchange="selectedPaymentMethod = paymentMethodsOptionsActive.find(option => option.value === membershipData.paymentMethodId)"
                    :disabled="false"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
              </b-col>
            </b-row>
          </b-card>

          <!-- New comment -->
          <validation-provider
            #default="validationContext"
            :name="t('Memberships.data.comment')"
            rules="required"
          >
            <b-form-group
              :label="t('Memberships.data.comment')"
              label-for="observation"
            >
              <b-form-textarea
                id="observation"
                v-model="membershipData.observation"
                :state="getValidationState(validationContext)"
                :placeholder="t('Memberships.data.placeholderComment')+'...'"
                rows="2"
                :max-rows="2"
                :maxlength="100"
              />
              <span><small class="text-muted">{{ 100 - membershipData.observation.length }} / 100 </small></span>
            </b-form-group>
          </validation-provider>

          <!-- submit button -->
          <div class="container-fluid  mx-0 my-0 px-0 py-0">
            <b-row class="w-100">
              <b-col class="col-5 " />
              <b-col class="col-3 float-right">
                <b-button
                  variant="secondary"
                  data-dismiss="add-new-membership-sidebar"
                  :disabled="false"
                  @click="hideModal(resetForm)"
                >
                  {{ t('Memberships.buttons.cancel') }}
                </b-button>
              </b-col>
              <b-col class="col-3 float-right ml-1">
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="invalid"
                >
                  {{ t('Memberships.buttons.editMembership') }}
                  <feather-icon
                    v-if="loader"
                    icon="SettingsIcon"
                    size="15"
                    class="spinner"
                  />
                </b-button>
              </b-col>
            </b-row>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea, BRow, BCol, BFormRadioGroup, BInputGroup, BInputGroupPrepend, BCard,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onUnmounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import membershipStoreModule from '@/views/Memberships/membershipStoreModule'
import userStoreModule from '@/views/Clients/userStoreModule'
import { getUserData } from '@/auth/utils'
import useUsersView from '@/views/Clients/clients-view/useUsersView'
import { loader } from '@core/mixins/ui/globalMixin'

export default {
  components: {
    BCard,
    BInputGroupPrepend,
    BInputGroup,
    BFormRadioGroup,
    BCol,
    BRow,
    BFormTextarea,
    flatPickr,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [loader],
  model: {
    prop: 'isEditMembershipSidebarActive',
    event: 'update:is-edit-membership-sidebar-active',
  },
  props: {
    isEditMembershipSidebarActive: {
      type: Boolean,
      required: true,
    },
    planOptions: {
      type: Array,
      default: null,
      required: true,
    },
    paymentMethodsOptionsActive: {
      type: Array,
      default: null,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    selectedMembership: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      selectedPlan: ref({}),
      selectedPaymentMethod: ref({}),
      activePlanOptions: [],
    }
  },
  computed: {
    updatedTotal() {
      const { selectedPlan } = this
      const percentDiscount = 10
      if (selectedPlan && selectedPlan.cost && selectedPlan.cost > 0 && selectedPlan.discount) {
        return selectedPlan.cost - (selectedPlan.cost * percentDiscount / 100)
      }
      return selectedPlan ? selectedPlan.cost : null
    },
    membershipStatus() {
      if (this.membershipData.type === 1) {
        if ((this.userData.memberships && !this.userData.memberships[0].isExpired && (this.userData.memberships[0].rootClient.id === this.userData.id))) {
          return 1
        }
        if ((this.userData.memberships && this.userData.memberships[0].isExpired) || (!this.userData.memberships)) {
          return 2
        }
        if (this.userData.memberships[0].rootClient.id != this.userData.id) {
          return 3
        }
      }
    },
  },
  mounted() {
    // Filtra solo los plasnes que están activos
    this.getPlansActive()
  },
  updated() {
    this.resetForm()
    this.setUpdateData()
  },
  methods: {
    getUserData,
    hideModal(resetForm) {
      resetForm()
      this.$refs['edit-membership-sidebar'].hide()
    },
    getPlansActive() {
      this.activePlanOptions = this.planOptions.filter(option => option.status === '1')
    },
    setUpdateData() {
      if (this.isEditMembershipSidebarActive) {
        this.membershipData.id = this.selectedMembership.id
        this.membershipData.clientId = this.selectedMembership.client.id
        this.membershipData.startDate = this.selectedMembership.start
        this.membershipData.endDate = this.selectedMembership.end
        this.membershipData.type = (this.selectedMembership.client.id === this.userData.id) ? 0 : 1
        this.membershipData.planId = this.selectedMembership.plan.id.toString()
        this.membershipData.paymentMethodId = this.selectedMembership.paymentMethod.id.toString()
        this.membershipData.credits = this.selectedMembership.credits
        this.membershipData.status = this.selectedMembership.status.id
        this.membershipData.debt = this.selectedMembership.debt
      } else {
        this.resetMembershipData()
      }
    },
  },
  setup(props, { emit }) {
    const blankMembershipData = {
      id: null,
      type: null,
      clientId: null,
      planId: null,
      credits: null,
      rootClientId: null,
      startDate: null,
      endDate: null,
      userId: null,
      debt: null,
      status: null, // status 4 parcialmente pagada
      isPaymentDebt: false,
      observation: '',
    }
    const MEMBERSHIP_APP_STORE_MODULE_NAME = 'app-membership'
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Register module
    if (!store.hasModule(MEMBERSHIP_APP_STORE_MODULE_NAME)) store.registerModule(MEMBERSHIP_APP_STORE_MODULE_NAME, membershipStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEMBERSHIP_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBERSHIP_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const membershipData = ref(JSON.parse(JSON.stringify(blankMembershipData)))
    const resetMembershipData = () => {
      membershipData.value = JSON.parse(JSON.stringify(blankMembershipData))
    }
    const toast = useToast()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetMembershipData)

    // Cambiar a consumirlo desde la propia clase de membership
    const { t, fetchUser } = useUsersView()
    const typeOptions = [
      { label: t('Memberships.type.personal'), value: 0 },
      { label: t('Memberships.type.group'), value: 1 },
    ]

    const paymentYesNoOptions = [
      { text: 'No', value: false },
      { text: 'Sí', value: true },
    ]

    const resolvePaymentDbto = value => {
      if (value) return t('Planes.discount.yes')
      return t('Planes.discount.no')
    }
    const onSubmit = userData => {
      store.commit('enableLoader')
      store.dispatch('app-membership/editMembership', {
        id: membershipData.value.id,
        type: membershipData.value.type,
        clientId: membershipData.value.type === 0 ? userData.id : membershipData.value.clientId,
        planId: membershipData.value.planId,
        credits: membershipData.value.credits,
        rootClientId: userData.id,
        isPaymentDebt: membershipData.value.isPaymentDebt,
        startDate: membershipData.value.startDate,
        status: membershipData.value.status,
        observation: membershipData.value.observation,
        paymentMethodId: membershipData.value.paymentMethodId,
      })
        .then(async () => {
          emit('update:is-edit-membership-sidebar-active', false)
          emit('handle-update-client')
          toast({
            component: ToastificationContent,
            props: {
              title: 'Aviso',
              text: 'Contratación editada con éxito',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          store.commit('disableLoader')
        }).catch(error => {
          console.log(error)
          if (error.response.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: error.response.data.error,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            store.commit('disableLoader')
          }
        })
    }

    return {
      paymentYesNoOptions,
      resolvePaymentDbto,
      typeOptions,
      membershipData,
      onSubmit,
      toast,
      refFormObserver,
      getValidationState,
      resetMembershipData,
      resetForm,
      t,
      fetchUser,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-membership-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
